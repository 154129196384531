<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Edit Brand Tax</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12" class="mb-3">
        <vs-input
          class="w-full"
          label="Brand Name"
          disabled
          v-model="payload.hotelName"
        />
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <vs-input
          type="number"
          class="w-full"
          label="Tax (*)"
          v-model="payload.tax"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("tax")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <vs-input
          type="number"
          class="w-full"
          label="Service Fee (*)"
          v-model="payload.fee"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("service_fee")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

// Store Module
import moduleBrandTaxManagement from "@/store/brand-tax-management/moduleBrandTaxManagement.js";

export default {
  name: "BrandTaxEdit",

  metaInfo: {
    title: "Edit Brand Tax",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    }
  },

  data: () => ({
    payload: {
      id: null,
      hotelName: '',
      hotelId: '',
      tax: 0,
      fee: 0
    },
    brandOptions: []
  }),

  methods: {
    getBrands() {
      axios
        .get("brand")
        .then(({ data: res }) => {
          this.brandOptions = res.data
            .map(brand => {
              return {
                id: brand.id,
                name: brand.name
              };
            });
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    getData () {
      this.$store.dispatch('brandTaxManagement/fetchBrandTax', this.decryptedID)
                 .then(({data: res}) => {
                   this.payload.id = res.data.id;
                   this.payload.hotelName = res.data.hotelName;
                   this.payload.hotelId = res.data.hotelId;
                   this.payload.tax = res.data.tax;
                   this.payload.fee = res.data.fee;
                 })
                 .catch(err => this.$catchErrorResponse(err))
    },

    storeData() {
      const payload = {
        ...this.payload,
      }
      delete payload.hotelName;
      this.$store
        .dispatch("brandTaxManagement/updateBrandTax", this.payload)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/brand-taxes");
        })
        .catch(err => {
          this.$catchErrorResponse(err);
        });
    }
  },

  mounted() {
  },

  created() {
    if (!moduleBrandTaxManagement.isRegistered) {
      this.$store.registerModule(
        "brandTaxManagement",
        moduleBrandTaxManagement
      );
      moduleBrandTaxManagement.isRegistered = true;
    }

    this.getData();
    // this.getBrands();
  }
};
</script>

<style></style>
